import { Empty } from 'zhs-components';
import CardHeader from '../../components/CardHeader.vue';
export default {
  props: {
    menu: Object
  },
  components: {
    Empty,
    CardHeader
  },

  created() {
    this.getNewsClassifyList();
  },

  watch: {
    classifyId: {
      handler(val) {
        this.getNewsList();
      },

      immediate: true
    }
  },

  data() {
    return {
      newsList: [],
      classifyList: [],
      classifyId: '',
      hiddenMoreClass: true
    };
  },

  methods: {
    // 增加观看次数
    async addViewCount(id) {
      const res = await this.$http.addViewCount({
        id
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      console.log('count', res);
    },

    handleLookDetail(item) {
      if (item.news_type === 1) {
        window.location.href = `/noticeDetail.html?type=1&id=${item.id}&name=${this.menu.module_name}&menuId=${this.menu.menu_id}`;
      } else {
        this.addViewCount(item.id);
        window.open(item.news_url);
      }
    },

    async getNewsList() {
      const res = (await this.$http.getHomeNews({
        num: this.menu.num,
        menu_id: this.menu.menu_id,
        classify_id: this.classifyId
      })) || [];
      console.log('新闻-首页', res);
      this.newsList = res;
    },

    async getNewsClassifyList() {
      const res = await this.$http.getNewsClassify({
        menu_id: this.menu.menu_id
      });
      console.log('getNewsClassifyList', res);
      this.classifyList = res;
    },

    searchCourseList(id) {
      this.classifyId = id;
    }

  }
};
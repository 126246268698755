import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-36f09eda"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item");

  const _component_el_carousel = _resolveComponent("el-carousel");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sec_banner", $data.bannerList.length <= 1 ? 'hiddenSlides' : ''])
  }, [$data.bannerList.length > 0 ? (_openBlock(), _createBlock(_component_el_carousel, {
    key: 0,
    arrow: ($data.bannerList && $data.bannerList.length) > 1 ? 'hover' : 'never',
    height: "330px"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.bannerList, ({
      id,
      file_url,
      target_type,
      target_url
    }) => {
      return _openBlock(), _createBlock(_component_el_carousel_item, {
        class: "banner-item",
        key: id
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "banner-wrapper",
          onClick: $event => $options.getBannerUrl(target_type, target_url, id)
        }, [_createElementVNode("img", {
          class: "banner-img",
          src: file_url || require('../../../assets/images/banner .png')
        }, null, 8, _hoisted_2)], 8, _hoisted_1)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["arrow"])) : _createCommentVNode("", true)], 2);
}
import TipDialog from "./TipDialog.vue";
export default {
  components: {
    TipDialog
  },
  props: {
    menuList: Array,
    zhsRole: Number,
    // 角色
    onlineLink: String // 在线学堂链接

  },
  inject: ['uuid', 'userId'],

  data() {
    return {
      showDialog: false,
      home_zhs_bind_sso: false
    };
  },

  created() {
    this.getBindZhsType();
  },

  methods: {
    async getBindZhsType() {
      const data = await this.$http.getBindZhsType();
      this.home_zhs_bind_sso = data.some(item => item.key == 'home_zhs_bind_sso');
    },

    handleLink(url) {
      if (url) {
        window.open(url);
      } else {
        this.showDialog = true;
      }
    },

    goToBind() {
      const host = window.location.origin;

      if (this.home_zhs_bind_sso) {
        window.location.href = `${host}/homepage/hike-boot/ssoLogin/zhsSsoLogin?redirectUri=${host}`;
      } else {
        const userId = this.userId();
        window.location.href = `${host}/homepage/hike-boot/AuthSys/getAuthorize?failRedirectUri=${host}&redirectUri=${host}&userId=${userId}`;
      }
    }

  }
};
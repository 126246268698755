import CourseCard from './CourseCard.vue';
import CardHeader from '@/pages/components/CardHeader';
import { Empty } from 'zhs-components';
import UserCard from './UserCard/index';
import SpocCourseCard from '@/pages/components/SpocCourseCard.vue';
export default {
  components: {
    CourseCard,
    Empty,
    CardHeader,
    UserCard,
    SpocCourseCard
  },
  props: {
    courseType: Number,
    // 1 mooc 2 spoc
    menu: Object,
    userInfo: {
      type: Object,
      default: null
    },
    zhsRole: {
      type: Number || String,
      default: 0
    },
    isTop: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.getAuth();

    if (this.courseType == 1) {
      this.getTypeList();
    }
  },

  watch: {
    typeId: {
      handler(val) {
        this.getHomeCourseList();
      },

      immediate: true
    }
  },

  data() {
    return {
      homeCourseList: [],
      courseCategorys: [],
      authType: 0,
      typeList: [],
      hiddenMoreClass: true,
      typeId: ''
    };
  },

  methods: {
    async getHomeCourseList() {
      const homeCourse = await this.$http[this.courseType == 1 ? 'getHomeMooc' : 'getHomeSpoc']({
        num: this.menu.num,
        menu_id: this.menu.menu_id,
        classify_id: this.courseType == 1 ? this.typeId : undefined
      });
      this.homeCourseList = homeCourse || [];
    },

    async getAuth() {
      const res2 = await this.$http.getConfigList();
      const result = res2.find(item => item.code == (this.courseType == 1 ? '10401' : '10402'));
      this.authType = result ? result.auth_type : 0;
    },

    async getTypeList() {
      const res = await this.$http.getCourseType({
        menu_id: this.menu.menu_id
      });
      this.typeList = res;
    },

    searchCourseList(id) {
      this.typeId = id;
    }

  }
};
export default {
  props: {
    show: Boolean
  },
  emits: ["update:show"],
  methods: {
    closeDialog() {
      this.$emit("update:show", false);
    }

  }
};
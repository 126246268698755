export default {
  props: {
    teacherData: {
      required: true,
      defalut: {},
      type: Object
    },
    menu: Object
  },
  methods: {
    openDetail() {
      window.open(`/teacherDetailInfo.html?id=${this.teacherData.teacher_id || this.teacherData.id}&usercode=${this.teacherData.usercode}&name=${this.menu.module_name}&menuId=${this.menu.menu_id}`);
    }

  }
};
import { handleLogin } from '@/plugins/utils';
export default {
  props: {
    courseData: {
      required: true,
      type: Object
    },
    userInfo: {
      type: Object,
      default: null
    },
    authType: Number
  },
  methods: {
    goDetail() {
      if (this.authType == 2 && !this.userInfo) {
        handleLogin();
      } else {
        window.location.href = this.courseData.course_url;
      }
    }

  }
};
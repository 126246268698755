// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/home/bg3.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".el-button.is-plain[data-v-536b72e4]{--el-button-active-text-color:var(--el-color-primary);--el-button-active-border-color:var(--el-color-primary)}.el-button--primary.is-plain[data-v-536b72e4]{--el-button-border-color:var(--el-color-primary-light-3)}.el-link.el-link--primary[data-v-536b72e4]:hover{color:var(--el-color-primary-light-3)}.teach-data[data-v-536b72e4]{min-height:164px;background-color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat}.teach-data .data-wrap[data-v-536b72e4]{width:1200px;margin:0 auto;display:flex;flex-wrap:wrap;padding-bottom:20px}.teach-data .data-wrap .data-card[data-v-536b72e4]{flex:25%;padding-top:48px}.teach-data .data-wrap .data-card a[data-v-536b72e4]{color:#fff}.teach-data .data-wrap .data-card .content[data-v-536b72e4]{display:flex;flex-direction:column;justify-content:center;align-items:center}.teach-data .data-wrap .data-card .content .count[data-v-536b72e4]{height:47px;font-size:36px;font-family:TimesNewRomanPSMT;font-weight:700;color:var(--el-color-primary);line-height:47px;letter-spacing:2px}.teach-data .data-wrap .data-card .content .text[data-v-536b72e4]{margin-top:6px;height:28px;font-size:16px;font-family:PingFangSC-Medium,PingFang SC;font-weight:500;color:#222;line-height:28px;letter-spacing:1px}", ""]);
// Exports
module.exports = exports;

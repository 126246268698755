export default {
  created() {
    this.getBanners();
  },

  data() {
    return {
      bannerList: []
    };
  },

  methods: {
    async getBanners() {
      const bannerList = await this.$http.getBannerList();
      this.bannerList = bannerList;
    },

    getBannerUrl(target_type, target_url, id) {
      if (!target_url) return;

      switch (target_type) {
        case 1:
        case 3:
          target_url && window.open(target_url);
          break;

        case 2:
          window.location.href = `/${$schoolUrl}/bannerDetail.html?bannerCon=${id}`;

        default:
          break;
      }
    }

  }
};
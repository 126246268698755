export default {
  props: {
    isCircle: {
      type: Boolean,
      default: false
    },
    bodyStyle: {
      type: Object,
      default: () => ({
        background: "#fff"
      })
    },
    link: {
      type: String
    },
    openOut: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  created() {},

  mounted() {},

  methods: {}
};
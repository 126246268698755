import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4d269d69"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "home-wrapper"
};
const _hoisted_2 = {
  class: "home-list-content"
};
const _hoisted_3 = {
  class: "course-resource"
};
const _hoisted_4 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Head = _resolveComponent("Head");

  const _component_BannerList = _resolveComponent("BannerList");

  const _component_Course = _resolveComponent("Course");

  const _component_Lecture = _resolveComponent("Lecture");

  const _component_SpocCourse = _resolveComponent("SpocCourse");

  const _component_TeachingCenterList = _resolveComponent("TeachingCenterList");

  const _component_News = _resolveComponent("News");

  const _component_TeacherList = _resolveComponent("TeacherList");

  const _component_TeacherData = _resolveComponent("TeacherData");

  const _component_Link = _resolveComponent("Link");

  const _component_Footer = _resolveComponent("Footer");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_Head, {
    menuCode: $data.menuCode,
    onGetUserInfo: $options.getUserInfo,
    menuInfo: $data.menuInfo,
    "onUpdate:menuInfo": _cache[0] || (_cache[0] = $event => $data.menuInfo = $event),
    onUserRole: $options.getZhsRole
  }, null, 8, ["menuCode", "onGetUserInfo", "menuInfo", "onUserRole"]), $data.bannerItem ? (_openBlock(), _createBlock(_component_BannerList, {
    key: 0
  })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$data.moocItem || $data.spocItem ? (_openBlock(), _createBlock(_component_Course, {
    key: 0,
    courseType: $data.moocItem ? 1 : 2,
    menu: $data.moocItem || $data.spocItem,
    userInfo: $data.userInfo,
    zhsRole: $data.zhsRole,
    isTop: !!$data.bannerItem,
    class: "course-item"
  }, null, 8, ["courseType", "menu", "userInfo", "zhsRole", "isTop"])) : _createCommentVNode("", true), $data.lectureItem && $data.lectureItem.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.lectureItem, item => {
    return _openBlock(), _createBlock(_component_Lecture, {
      key: item.id,
      menu: item
    }, null, 8, ["menu"]);
  }), 128))])) : _createCommentVNode("", true), $data.moocItem && $data.spocItem ? (_openBlock(), _createBlock(_component_SpocCourse, {
    key: 2,
    menu: $data.spocItem,
    userInfo: $data.userInfo
  }, null, 8, ["menu", "userInfo"])) : _createCommentVNode("", true), $data.teachingCenterItem ? (_openBlock(), _createBlock(_component_TeachingCenterList, {
    key: 3,
    menu: $data.teachingCenterItem
  }, null, 8, ["menu"])) : _createCommentVNode("", true)]), $data.newsItem ? (_openBlock(), _createBlock(_component_News, {
    key: 0,
    menu: $data.newsItem
  }, null, 8, ["menu"])) : _createCommentVNode("", true), $data.teacherItem ? (_openBlock(), _createBlock(_component_TeacherList, {
    key: 1,
    menu: $data.teacherItem
  }, null, 8, ["menu"])) : _createCommentVNode("", true)]), $data.dataItem ? (_openBlock(), _createBlock(_component_TeacherData, {
    key: 1,
    menu: $data.dataItem
  }, null, 8, ["menu"])) : _createCommentVNode("", true), $data.footerItem ? (_openBlock(), _createBlock(_component_Link, {
    key: 2,
    menu: $data.footerItem
  }, null, 8, ["menu"])) : _createCommentVNode("", true), _createVNode(_component_Footer)])]);
}
import Head from './../components/Head';
import Footer from './../components/Footer';
import Course from './components/Course';
import SpocCourse from './components/SpocCourse.vue';
import TeacherList from './components/TeacherList';
import BannerList from './components/BannerList';
import News from './components/News';
import TeacherData from './components/TeachData.vue';
import Link from '../components/aboutLink.vue';
import TeachingCenterList from './components/TeachingCenterList.vue';
import Lecture from './components/Lecture/index.vue';
export default {
  components: {
    Head,
    Course,
    TeacherList,
    BannerList,
    News,
    Footer,
    SpocCourse,
    TeacherData,
    Link,
    TeachingCenterList,
    Lecture
  },

  provide() {
    return {
      uuid: () => this.uuid,
      userId: () => this.userId
    };
  },

  created() {
    this.getDataList(); // this.getLive();
  },

  data() {
    return {
      menuCode: '10301',
      dataList: [],
      liveList: [],
      footerItem: null,
      dataItem: null,
      bannerItem: null,
      moocItem: null,
      spocItem: null,
      newsItem: null,
      teacherItem: null,
      teachingCenterItem: null,
      menuInfo: {},
      userInfo: null,
      zhsRole: 0,
      uuid: '',
      userId: '',
      lectureItem: null
    };
  },

  methods: {
    async getDataList() {
      const res = await this.$http.getDataList();
      console.log('首页数据', res);
      this.dataList = res;
      this.footerItem = this.dataList.find(item => item.function_id == '10303' && item.home_open);
      this.dataItem = this.dataList.find(item => item.function_id == '10304' && item.home_open);
      this.bannerItem = res.find(item => item.function_id == '10302' && item.home_open);
      this.moocItem = res.find(item => item.function_id == '10401' && item.home_open);
      this.spocItem = res.find(item => item.function_id == '10402' && item.home_open);
      this.newsItem = res.find(item => item.function_id == '10701' && item.home_open);
      this.teacherItem = res.find(item => item.function_id == '10501' && item.home_open);
      this.teachingCenterItem = res.find(item => item.function_id == '51001' && item.home_open);
      this.lectureItem = this.dataList.filter(item => item.function_id == '40005' && item.home_open);
      console.log('lee', this.lectureItem);
    },

    getUserInfo(data) {
      this.userInfo = data;
      this.uuid = data.uuid;
      this.userId = data.id;
    },

    getZhsRole(data) {
      this.zhsRole = data || 0;
    }

  }
};
export default {
  name: "Footer",
  props: {
    menu: Object
  },

  data() {
    return {
      links: []
    };
  },

  created() {
    this.getLink();
  },

  methods: {
    async getLink() {
      const res = await this.$http.getFooterLink({
        num: this.menu.num
      });
      this.links = res;
    }

  }
};
import CardHeader from '../../components/CardHeader.vue';
import TeacherCard from 'components/TeacherCard';
import { Empty } from 'zhs-components';
export default {
  components: {
    TeacherCard,
    Empty,
    CardHeader
  },
  props: {
    menu: Object
  },

  created() {
    this.getTeacherList();
  },

  data() {
    return {
      teacherList: [],
      dialogTeacherVisible: false,
      currentTeacher: {}
    };
  },

  methods: {
    async getTeacherList() {
      const list = await this.$http.getHomeTeachers({
        num: this.menu.num,
        menu_id: this.menu.menu_id
      });
      console.log('名师', list);
      this.teacherList = list;
    }

  }
};
import CardHeader from '../../components/CardHeader.vue';
import CourseCard from './../../components/SpocCourseCard.vue';
import { Empty } from 'zhs-components';
export default {
  props: {
    courseType: Number,
    menu: Object,
    userInfo: {
      type: Object,
      default: null
    }
  },
  components: {
    CourseCard,
    CardHeader,
    Empty
  },

  created() {
    this.getAuth();
    this.getHomeCourseList();
    console.log(this.authType);
  },

  data() {
    return {
      homeCourseList: [],
      courseCategorys: [],
      classId: undefined,
      authType: 0
    };
  },

  methods: {
    async getHomeCourseList() {
      const homeCourse = await this.$http.getHomeSpoc({
        num: this.menu.num,
        menu_id: this.menu.menu_id
      });
      this.homeCourseList = homeCourse || [];
    },

    async getAuth() {
      const res2 = await this.$http.getConfigList();
      this.authType = res2.find(item => item.code == '10402') ? res2.find(item => item.code == '10402').auth_type : 0;
    }

  }
};
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1f46fcca"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "teacher-photo"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "name text-overflow"
};
const _hoisted_4 = ["title"];
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "teacher-card",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.openDetail && $options.openDetail(...args))
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
    src: $props.teacherData.teacher_avatar_url || require('@/assets/images/common/teacher_icon.png'),
    alt: ""
  }, null, 8, _hoisted_2)]), _createElementVNode("div", _hoisted_3, _toDisplayString($props.teacherData.teacher_name), 1), _createElementVNode("div", {
    class: "teacher-info text-overflow",
    title: $props.teacherData.organ_name && $props.teacherData.title ? $props.teacherData.organ_name + ' ' + $props.teacherData.title : $props.teacherData.organ_name || $props.teacherData.title
  }, [_createElementVNode("span", null, _toDisplayString($props.teacherData.organ_name), 1), $props.teacherData.title ? (_openBlock(), _createElementBlock("span", _hoisted_5, " " + _toDisplayString($props.teacherData.title), 1)) : _createCommentVNode("", true)], 8, _hoisted_4), _createElementVNode("div", {
    class: "intro text-overflow-two",
    "data-html": "",
    innerHTML: _ctx.$utils.getContentText($props.teacherData.introduction)
  }, null, 8, _hoisted_6)]);
}
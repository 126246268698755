import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-536b72e4"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "teach-data"
};
const _hoisted_2 = {
  class: "data-wrap"
};
const _hoisted_3 = ["target", "href"];
const _hoisted_4 = {
  class: "content"
};
const _hoisted_5 = {
  class: "count"
};
const _hoisted_6 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $data.teachData && $data.teachData.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.teachData, ({
    name,
    num,
    targetUrl
  }, i) => {
    return _openBlock(), _createElementBlock("div", {
      class: "data-card",
      key: i
    }, [_createElementVNode("a", {
      target: targetUrl ? '_blank' : '_self',
      href: targetUrl ? targetUrl : 'javascript:void(0)'
    }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(num), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(name), 1)])], 8, _hoisted_3)]);
  }), 128))])])) : _createCommentVNode("", true);
}
export default {
  props: {
    menu: Object
  },

  created() {
    this.getTeachData();
  },

  data() {
    return {
      teachData: []
    };
  },

  methods: {
    async getTeachData() {
      const teachData = await this.$http.getTeachData({
        num: this.menu.num
      }); // this.teachData = teachData;

      console.log('数据模块', teachData);
      this.teachData = this.handleUrl(teachData);
    },

    handleUrl(data) {
      const urlList = {
        10301: '/index.html?',
        10401: '/courseList.html?type=1&',
        10402: '/courseList.html?type=2&',
        10501: '/teacherList.html?',
        10601: '/live.html?',
        10701: '/notice.html?',
        30200: '/teachingCenterList.html?',
        13100: '/microSpecialtyList.html?'
      }; // 点击导航跳转到对应taget_url,并传参

      const list = data.map(item => ({ ...item,
        targetUrl: item.template == 1 ? item.targetUrl : item.template == 2 && item.targetUrl ? `${urlList[item.functionId]}menuId=${item.menuId}&name=${item.name}` : ''
      }));
      return list;
    }

  }
};
import RoleCard from './RoleCard.vue';
import { handleLogin } from '@/plugins/utils';
export default {
  components: {
    RoleCard
  },
  props: {
    userInfo: {
      type: Object,
      default: null
    },
    zhsRole: {
      type: Number || String,
      default: 0
    }
  },
  watch: {
    zhsRole: {
      handler(value) {
        this.getAppList(value);
        let result = [];

        if (value == 1) {
          result = [{
            name: '管理员',
            role: 1
          }, {
            name: '教师',
            role: 2
          }, {
            name: '学生',
            role: 3
          }];
        } else if (value == 2) {
          result = [{
            name: '教师',
            role: 2
          }, {
            name: '学生',
            role: 3
          }];
        }

        this.roleList = result;
        this.currentRole = value;
      },

      immediate: true
    }
  },

  data() {
    return {
      roleList: [],
      currentRole: 0,
      // 学堂链接
      onlineLink: {
        manage: 'https://onlineweb.zhihuishu.com/onlineMuster/managerIndex',
        teacher: 'https://onlineweb.zhihuishu.com/onlineMuster/teacherIndex',
        student: 'https://onlineweb.zhihuishu.com/onlinestuh5'
      },
      appList: [],
      manageMenu: [{
        name: '教学指挥中心',
        link: 'https://hike-course-homepage.zhihuishu.com/managerCenter/home?sid=1204',
        img: require('@/assets/images/home/教学指挥中心.png')
      }, {
        name: '数据大屏',
        link: 'https://dch5.zhihuishu.com/dailyData.html#/dailyData1/4Yda9gdv?sid=1204',
        img: require('@/assets/images/home/数据大屏.png')
      }, {
        name: '数据中心',
        link: 'https://dch5.zhihuishu.com/newDataCenter.html#/dataOverview?sid=1204',
        img: require('@/assets/images/home/数据中心.png')
      }, {
        name: '教学日报',
        link: 'https://dch5.zhihuishu.com/dailyData.html#/dailyData/4Yda9gdv',
        img: require('@/assets/images/home/教学日报.png')
      }, {
        name: '教研中心',
        link: '/teachCenter.html',
        img: require('@/assets/images/home/教研中心.png')
      }, {
        name: '评审平台',
        link: 'https://cdtupms.zhihuishu.com',
        img: require('@/assets/images/home/金课评审.png')
      }, {
        name: '后台管理',
        link: 'https://cdtu.zhihuishu.com/admin/#/dashboard/index',
        img: require('@/assets/images/home/评审平台.png')
      }, {
        name: '帮助中心',
        link: 'https://hike-course-homepage.zhihuishu.com/helpCenter',
        img: require('@/assets/images/home/帮助中心1.png')
      }],
      teacherMenu: [{
        name: '教研中心',
        link: '/teachCenter.html',
        img: require('@/assets/images/home/教研中心.png')
      }, {
        name: '题库',
        link: 'https://questionbankh5.zhihuishu.com/questionList/1/1?tab=1&source=1',
        img: require('@/assets/images/home/题库.png')
      }, {
        name: '资源库',
        link: 'https://hikeweb.zhihuishu.com/RSRCcenter/resourceCenter/resourceCenter/cloudLibrary',
        img: require('@/assets/images/home/资源库.png')
      }, {
        name: 'AI资源库',
        link: 'https://hikeweb.zhihuishu.com/hike-tch/resource?type=2',
        img: require('@/assets/images/home/AI资源库.png')
      }, {
        name: '金课评审',
        link: 'https://hike-course-homepage.zhihuishu.com/goldenCourse',
        img: require('@/assets/images/home/金课评审.png')
      }, {
        name: '群文件管理',
        link: 'https://ct.zhihuishu.com/classroomTools/gpFile/home',
        img: require('@/assets/images/home/群文件管理1.png')
      }, {
        name: '评审平台',
        link: 'https://cdtupms.zhihuishu.com',
        img: require('@/assets/images/home/评审平台.png')
      }, {
        name: '帮助中心',
        link: 'https://hike-course-homepage.zhihuishu.com/helpCenter',
        img: require('@/assets/images/home/帮助中心1.png')
      }],
      studentMenu: [{
        name: '消息通知',
        link: 'https://onlineh5.zhihuishu.com/onlineWeb.html#/student/noticeCenter?entry=1&noticeType=0',
        img: require('@/assets/images/home/帮助中心.png')
      }, {
        name: '群文件管理',
        link: 'https://ct.zhihuishu.com/classroomTools/gpFile/home',
        img: require('@/assets/images/home/群文件管理.png')
      }, {
        name: '帮助中心',
        link: 'https://hike-course-homepage.zhihuishu.com/helpCenter',
        img: require('@/assets/images/home/帮助中心.png')
      }]
    };
  },

  methods: {
    toggleZhsRole(data) {
      this.currentRole = data.role;
      this.getAppList(data.role);
    },

    zhsLogin() {
      handleLogin(this.$schoolId); // const host = window.location.protocol + '//' + window.location.host
      // // const loginUrl = host + '/index.html'
      // const url = host + '/index.html'
      // window.location.href = `${host}/hike-boot/AuthSys/getAuthorize?redirectUri=${url}&failRedirectUri=${url}&source=${this.$schoolId}`
    },

    async getAppList(e) {
      const params = {
        role_type: e
      };
      const data = await this.$http.getAppList(params);
      this.appList = data.filter(item => item.app_name != 'zhs_dz_platform');
      const onlineUrl = data.find(item => item.app_name == 'zhs_dz_platform').jump_url;

      if (onlineUrl) {
        if (e == 1) {
          this.onlineLink.manage = onlineUrl;
        } else if (e == 2) {
          this.onlineLink.teacher = onlineUrl;
        } else if (e == 3 || e == 0) {
          this.onlineLink.student = onlineUrl;
        }
      } else {
        this.getOnlineLink();
      }
    },

    async getOnlineLink() {
      const params = {
        role_type: 0
      };
      const data = (await this.$http.getAppList(params)) || [];

      if (data.length) {
        for (const key in this.onlineLink) {
          this.onlineLink[key] = data[0].jump_url;
        }
      }
    }

  }
};